@import '../../theme/base_colors';

@import 'react-big-calendar/lib/sass/styles';

.boxScheduleActiveEvent {
  background: $green !important;
  width: 80% !important;
  color: black;
  pointer-events: none;
}

.boxScheduleDisabledActiveEvent {
  background: $form_grey !important;
  width: 80% !important;
  color: black;
  pointer-events: none;
}

.deleteModeEvent {
  background: $error_red !important;
  color: white;
  cursor: pointer;
  pointer-events: all;
}

.selectionModeEvent {
  background: orange;
  color: transparent;
  min-height: 5px !important;
  max-height: 10px !important;
}

.rbc-toolbar {
  background: $form_grey;
  padding-top: 3px;
  padding-bottom: 3px;
  .rbc-toolbar-label {
    display: none;
  }

  button {
    border-radius: 0;
    background: white;
    margin-inline: 5px;
    cursor: pointer;
    &:hover {
      background: white;
      color: $light_main;
      border-color: $light_main;
    }
  }
  .rbc-active {
    background: white !important;
    color: $main !important;
    border-color: $main !important;
    box-shadow: none !important;
  }
}
.rbc-today {
  background: $extra_light_main;
}
.rbc-time-content {
  .rbc-current-time-indicator {
    background: $main;
  }
}

.rbc-time-header {
  .rbc-button-link {
    cursor: default !important;
  }
}

.rbc-allday-cell {
  display: none;
}
.rbc-header {
  border-bottom: 0;
}

.rbc-event-label {
  white-space: normal;
}

.scc--box-schedule-range-picker {
  margin-top: 3px;
  margin-bottom: 3px;
}
.scc--list-item-small-padding {
  padding: 2px;
}

.scc--box-schedule-alert {
  margin-top: 5px;
  margin-bottom: 5px;
}

.scc--box-schedule-calendar {
  .rbc-day-slot {
    cursor: pointer;
  }
}

.scc--box-schedule-calendar-delete {
  .rbc-time-content {
    cursor: not-allowed;
  }
}
