@import '../../theme/carbon_colors';

.scc--stream--add-button {
  margin-top: 10px;
  font-weight: bold;
}

.scc--stream--delete-button {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  min-height: 2.5rem;
  padding: 10px;
  line-height: 1.25rem;
  border-radius: 0;
  height: auto;
}

.scc--stream--delete-button-hide-tooltip {
  display: none;
}

.scc--stream--accordion-item {
  .bx--accordion__content {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .bx--accordion__title {
    font-weight: bold;
    font-size: 1rem;
  }

  fieldset.scc--connection {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.scc--deviceconfig-container {
  overflow: hidden;
  height: 100%;

  .scc--location-picker-row {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    width: 100%;
    align-items: center;

    .bx--form-item {
      margin-right: 0px;
    }
    .scc--location-picker-button {
      margin-left: 20px;
      margin-bottom: 22px;
      margin-top: 0px;
    }
  }

  .bx--row {
    overflow: auto;
    height: 100%;

    .bx--row {
      margin-left: 0;
      margin-right: 0;
    }

    & > div {
      height: 100%;
    }
  }

  .bx--grid {
    margin-left: 1rem;
  }
}

.scc--device-config--title {
  width: 100%;
  background: #ffffff;

  legend {
    font-size: 1.25rem;
    color: $main !important;
    font-weight: bold !important;
  }
  .bx--fieldset {
    margin-bottom: 0;
  }
}

.ant-menu-sub.ant-menu-inline {
  background: none !important;
}

.scc--device-config--eventtypes--menu {
  width: 100% !important;
  color: $main;
  .ant-menu-submenu-title {
    padding-left: 0 !important;
  }
  .bx--tooltip__trigger {
    margin-right: 10px;
  }
}

.scc--required--hint {
  color: $text_light;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}

.scc--formfield__value-set {
  font-weight: bold;
  letter-spacing: 0.5px;

  .bx--select-input {
    font-weight: bold;
    letter-spacing: 0.5px;
  }
}

.bx--text-input {
  background-color: $form-fields-background;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 40px;

  &:focus,
  &:active {
    border-color: $main;
    outline: none;
  }

  &::placeholder {
    line-height: 40px;
    font-weight: normal;
    color: $text_light;
  }
}

.bx--select {
  width: 100%;
}

.bx--select-input {
  font-size: 0.875rem;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: $form-fields-background;
  width: 100%;
  max-width: none;
  line-height: 40px;

  &:focus,
  &:active {
    border-color: $main;
    outline: none;
  }
}

input:-internal-autofill-selected,
input:-webkit-autofill {
  background: $form-fields-background !important;
  font-size: 0.875rem;
}

.bx--label,
.bx--tooltip__label {
  font-size: 0.875rem;
  font-weight: normal;
  margin-top: 0.75rem;
  display: block;
  width: 100%;

  .bx--tooltip__trigger {
    float: right;

    svg {
      fill: $light_main;
    }

    &:hover svg,
    &:focus svg {
      fill: $main;
    }
  }

  &:empty {
    display: none;
  }
}

.scc--headerlabel {
  font-size: 1.25rem;
  color: $text_light;
  margin: 0;
  position: sticky;
  top: 0;
  background: #ffffff;
  width: 100%;
}

legend.bx--label {
  font-size: 1.25rem;
  color: $text_light;
  margin: 0;
  padding: 0.75rem 0 0.5rem;
  position: sticky;
  top: 0;
  background: #ffffff;
  width: 100%;
  border: 0px solid black;
}

legend.scc--icon--legend {
  padding-left: 2rem;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10%;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position-y: 0.6rem;
  }
}

legend.scc--icon--camera {
  &:before {
    background-image: url(../../theme/icons/camera_grey.svg);
  }
}

legend.scc--icon--connection {
  &:before {
    background-image: url(../../theme/icons/connection_grey.svg);
  }
}

legend.scc--icon--meta {
  &:before {
    background-image: url(../../theme/icons/doves.svg);
  }
}

.scc--stream-add-button {
  width: 8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  float: right;
}

.bx--form {
  margin-bottom: 1rem;
}

.bx--tooltip {
  width: 18rem;
  max-width: 18rem;

  .bx--tooltip__caret {
    left: auto;
    right: 5px;
  }
}

.bx--tooltip__trigger:focus {
  outline: none !important;
}

.bx--text-input--password__visibility__toggle {
  svg {
    fill: $text_light !important;
    outline: none !important;
  }

  &:hover svg,
  &:focus svg {
    fill: $text-grey !important;
  }
}

input[data-invalid],
.bx--text-input__field-wrapper[data-invalid],
.bx--text-area__wrapper[data-invalid] > .bx--text-area--invalid,
.bx--select-input__wrapper[data-invalid],
.bx--list-box[data-invalid],
.bx--combo-box[data-invalid] .bx--text-input {
  outline: none;
  border-color: $error;

  &:active,
  &:focus {
    outline: none;
    border-color: $error;
  }
}

.bx--select-input__wrapper[data-invalid] .bx--select-input,
.bx--select-input__wrapper[data-invalid] .bx--select-input:focus {
  outline: none;
  border: 1px solid $error;
}

.bx--select-input__wrapper {
  width: 100%;
}

.scc--close-button {
  display: block;
}

.scc--fill-main {
  fill: $light_main;

  a &:hover,
  a &:focus {
    fill: $main;
  }
}

.scc--boxdetail--canvas {
  position: relative;

  .bx--btn--primary {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
  }
}

.scc--carbonfield-row {
  .ant-checkbox-group {
    border: 1px solid;
    border-color: transparent;
    padding: 8px;
    width: 100%;
  }
  .ant-checkbox-group.invalid {
    border-color: #da1e28 !important;
    border-radius: 4px;
  }
}

.scc--icon--api {
  color: #abb9ca;
  display: flex;
  font-size: 20px;
  justify-content: center;
  padding-right: 10px;
}
.scc--ioconnection {
  padding-bottom: 2rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.scc--configheader {
  align-items: center;
}
.scc--config--tooltip {
  padding-left: 8px;
}
