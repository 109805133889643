@import '../../theme/carbon_colors';

.scc--onvif-basic {
  button {
    float: right;
  }

  .anticon-check {
    color: $green
  }

  td:first-of-type {
    font-weight: bold;
  }

  th:first-of-type {
    font-weight: bold;
  }

  .ant-btn {
    min-width: 12.5rem;
  }
}

.scc--onvif-advanced {

  .ant-table-wrapper {
    margin-bottom: 24px;
  }

  tr:hover {
    td {
      background: none !important;
    }
  }

  .scc--onvif-advanced-wdr {
    width: 100%;

    .ant-space-item:first-of-type {
      width: 5rem;
    }

    .ant-space-item:last-of-type {
      width: 100%;
    }
  }

  .ant-space {
    width: 100%;
  }

  .scc--camera-feed--refresh-button {
    right: 1rem;
    top: 0.7rem;
  }

  .scc--onvif-advanced-buttons {
    width: auto;
    float: right;
  }

  .scc--onvif-advanced-zoom {
    width: 100%;
    margin-bottom: 15px;
  }

  .scc--onvif-advanced-canvas {
    margin-bottom: 24px;
  }

  .ant-select {
    width: 50%;
  }

  thead {
    .ant-table-cell {
      font-weight: bold;
    }
  }

  .ant-btn-primary {
    min-width: 9.5rem;
  }
}

.scc--tablerow-disabled {
  color: gray;
  background: #fdfdfd;
}

.ant-spin {
  padding-right: 10px;
}
