@import '../../theme/carbon_colors';

.scc--camera-feed--wrapper {
  background: #f2f5f7;
  position: relative;
  height: 100%;
}

.scc--camera-feed--centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.scc--camera-feed--loading {
  .bx--loading {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.scc--camera-feed--icon {
  height: 1.5rem;
  text-align: center;
  display: block;
}

.scc--camera-feed--header,
.scc--camera-feed--text {
  font-size: 1rem;
  color: $text_light;
  text-align: center;
}

.scc--camera-feed--time {
  color: #ffffff;
  position: absolute;
  top: 1rem;
  left: 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  z-index: 1;
}

@keyframes flash-and-fadeout {
  0% {
    background-color: #ffffff;
    border-color: #ffffff;
  }
  10% {
    background-color: transparent;
    border-color: transparent;
  }
  20% {
    background-color: #ffffff;
    border-color: #ffffff;
  }
  30% {
    background-color: transparent;
    border-color: transparent;
  }
  40% {
    background-color: #ffffff;
    border-color: #ffffff;
  }
  100% {
    background-color: transparent;
    border-color: transparent;
  }
}

.scc--slider--trackCalibration {
  position: absolute;
  right: 1.5rem;
  bottom: 0.3rem;
  margin-bottom: 0 !important;
  z-index: 1;
  width: 20rem;
  animation: flash-and-fadeout 3s;

  .ant-slider-rail {
    background-color: $main !important;
  }

  .ant-slider-track {
    background-color: $main !important;
  }

  .ant-slider-handle {
    border-color: $main !important;
  }

  .ant-slider-dot {
    visibility: hidden;
  }

  .ant-slider-mark-text {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    white-space: nowrap;
  }
  .ant-slider-mark {
    top: -25px;
    bottom: auto;
  }
}

.scc--radio--calibrationMode {
  position: absolute;
  right: 0.6rem;
  top: 0.5rem;
  z-index: 1;
  width: 160px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.scc--camera-feed--refresh-button {
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  padding: 0;
  position: absolute;
  right: 13rem;
  top: 0.8rem;
  outline: none;
  z-index: 1;
  opacity: 0.7;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  img {
    display: block;
    width: 1rem;
    height: 1rem;
    position: relative;
    left: 0.25rem;
  }
}

.scc--camera-feed--refresh-button-spinning {
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  padding: 0;
  position: absolute;
  right: 13rem;
  top: 0.8rem;
  outline: none;
  z-index: 1;

  img {
    display: block;
    width: 1rem;
    height: 1rem;
    position: relative;
    left: 0.25rem;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.scc--camera-feed--auto-refresh {
  padding: 0;
  position: absolute;
  right: 15rem;
  top: 1rem;
  opacity: 0.7;
  z-index: 1;
  &:hover {
    opacity: 1;
  }
}

.scc--camera-feed--onvif {
  position: absolute;
  top: 0.5rem;
  left: 0.7rem;
  z-index: 1;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.scc--camera-feed--show-hide-all {
  position: absolute;
  font-size: 1rem;
  top: 0.7rem;
  right: 11rem;
  height: 1.6rem;
  width: 1.6rem;
  z-index: 1;
  padding: 0;
  opacity: 0.7;
  border: none;
  border-radius: 100%;
  &:hover {
    opacity: 1;
  }
}

.ant-tooltip-disabled-compatible-wrapper {
  padding: 0;
  border: none;
}

.scc--camera-feed--not-configured {
  width: 100%;
  height: 100%;
}

.scc--camera-feed--class-color-tooltip-icon {
  position: absolute;
  z-index: 1;
  top: 2.8rem;
  right: 0.7rem;
  color: #000000;
  background-color: rgb(255, 255, 255);
  font-size: 1rem;
  padding: 0.25rem;
  opacity: 0.7;
  border: none;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 100%;

  &:hover {
    opacity: 1;
  }
}
