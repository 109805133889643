.scc--account__info {
  font-size: 1rem;
}

.scc--account__menu-item {
  display: inline-block;
  font-size: 1rem;
  color: #000000;
}

.scc--account__logout {
  text-align: center;
  font-size: 1rem;
}

.scc--account__change-tenant {
  text-align: center;
  font-size: 1rem;
}

// only apply if submenu is disabled and be more specific in order to overwrite !important from ant default
.ant-menu-submenu-disabled.scc--account__disabled_without_effect {
  .ant-menu-submenu-title {
    color: #000000 !important;
    cursor: default;
  }
}
