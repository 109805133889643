@import '../../theme/carbon_colors';

.draw-config-form__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  .ant-btn {
    display: block;
    flex: 1;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;

    & + .ant-btn {
      margin-left: 1rem;
    }
  }
}

.draw-config-form {
  li .ant-menu-sub {
    margin-bottom: 0.5rem;
  }
}

.scc--crossingline--direction--wrapper {
  display: flex;
  align-content: center;
  cursor: pointer;

  .bx--label {
    cursor: pointer;
  }
}

.scc--crossingline--direction {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.25rem;
  background: #f2f5f7;
  border: 2px solid $text_light;
  border-radius: 4px;
  margin-right: 0.5rem;
}

.scc--crossingline--direction--caret {
  fill: $text_light;
  width: 0.8rem;
  height: 0.8rem;

  &.on {
    fill: $main;
  }
}

.scc--device-config--tabs {
  margin-top: 3rem;
}

.scc--device-config--tab {
  width: 49%;
}

.bx--tab-content {
  margin-bottom: 2rem;

  .bx--tooltip__label {
    margin-top: 2rem;
  }
}

.bx--toggle-input__label .bx--toggle__switch {
  margin-top: 0.2rem !important;
}

.bx--toggle-input:disabled
  + .bx--toggle-input__label
  > .bx--toggle__switch::before {
  background: #8d8d8d !important;
}

.bx--tab-content {
  padding: 0;
}
.bx--model-description {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  line-height: 1rem;
}

.bx--tabs--scrollable__nav-link {
  color: $text_light !important;
  outline: none !important;
  font-size: 1rem !important;
  line-height: 1rem !important;
  border-bottom: 3px solid #d8d8d7 !important;

  .bx--tabs--scrollable__nav-item--selected & {
    color: $main !important;
    border-bottom-color: $main !important;
  }
}

.scc--model--description {
  .ant-typography code {
    background: $extra_light_main;
    border-color: $main;
  }
}
