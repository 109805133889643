.scc--solutions--button {
  width: 8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  float: left;
}

.scc--solutions--edit-group-button {
  margin-left: 8px;
  margin-top: 5px;
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.scc--solutions--save-group-button {
  float: right;
  width: auto;
}

.bx--data-table th {
  width: auto !important;
}

.bx--table-header-first {
  min-width: 350px;
}

td .bx--data-table {
  vertical-align: middle;
}
