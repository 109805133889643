@import '../../theme/carbon_colors';

.scc--locationpicker {
  .ant-modal-title {
    color: $main;
    font-size: 20px;
    font-weight: bold;
  }
}

.scc--location-map-wrapper {
  height: 800px;
  position: relative;
  margin: 0;
}

.scc--location-address-search {
  width: 50%;
  position: absolute;
  top: 2%;
  left: 2%;
}
