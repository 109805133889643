@import '../../theme/carbon_colors';

.rule-based-badge {
  margin-left: 5px;
  .ant-badge-count {
    background-color: $main !important;
  }
}

.rule-engine--template {
  font-weight: bold;
}

.rule-engine--empty-query {
  .query-builder-container {
    border-color: #ff4d4f !important;
    border: 1px solid;
    border-radius: 2px;
  }
  .bx--tooltip__trigger {
    padding: 5px;
  }
}

.scc--btn--rule-engine-condition {
  margin-top: 10px;
}

.scc--tooltip-rule-engine {
  .scc--tooltip--label {
    font-size: 1rem;
    margin-bottom: 6px;
  }
}

.scc--rule-engine--iopin-invalid {
  .ant-select-selector {
    border-color: #ff4d4f !important;
  }
}

.sscc--rule-engine--iopin-invalid.ant-select-focused {
  .ant-select-selector {
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
  }
}

.query-builder-container {
  padding: 0 10px !important;
  .query-builder {
    margin: 0 !important;
  }

  .group--children {
    .group--header {
      padding-right: 0;
    }
    .group--field--count--rule {
      margin-left: 0 !important;
    }
  }

  .group {
    background-color: transparent !important;
    border: 0;
    padding: 2px;
  }

  .group--conjunctions {
    display: none;
  }

  .action--ADD-GROUP {
    display: none;
  }

  .query-builder
    > .group-or-rule-container
    > .group
    > .group--header
    > .group--actions--tr {
    justify-content: flex-start;
    opacity: 100 !important;
    margin-left: -10px;
  }

  .group--actions--tr {
    opacity: 100 !important;
  }

  .group-or-rule {
    padding: 2px 0 2px 0;
  }

  .rule {
    margin-left: -1px;
  }

  .rule-group {
    margin-left: 1px;
  }

  .rule--drag-handler {
    display: none;
  }

  .widget--valuesrc {
    opacity: 100 !important;
  }

  .action--DELETE {
    background: $error_red;
    border-color: $error_red;
    margin-top: 0px !important;
    margin-left: 2px !important;
  }
}
.empty-rule-query {
  border: 1px #ff4d4f solid !important;
  border-radius: 2px;
  padding: 2px;
}
