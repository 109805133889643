// === COLORS ===

$grey: #d3d3d3;
$main: var(--primary-color);
$light_main: var(--light-color);
$hover-main: var(--dark-color);
$focus-main: var(--dark-color);
$extra_light_main: var(--extra-light-color);
$green: #00a86b;
$text_grey: rgba(0, 0, 0, 0.5);
$text_dark_grey: rgba(0, 0, 0, 0.85);
$text_light: #abb9ca;
$text_dark: #000000;
$skeleton_grey: #d8d8d7;
$form_grey: #f2f5f7;
$error_red: #da1e28;

// light / dark colors for extra colors (except green -> original values) taken from lighten/darken(primary, 10)
// extra light taken by changing hue in hsv format of green-extra-light to hue of primary color
body.green > * {
  --primary-color: #00a86b;
  --light-color: #a1e0c1;
  --extra-light-color: #dbf3e7;
  --dark-color: #00754b;
}

body.blue > * {
  --primary-color: #0069aa;
  --light-color: #0089dd;
  --extra-light-color: #dae9f2;
  --dark-color: #004a77;
}

body.red > * {
  --primary-color: #ff0000;
  --light-color: #ff3333;
  --extra-light-color: #f3dbdb;
  --dark-color: #cc0000;
}
