@import '../../theme/carbon_colors';

.scc--edgeUpdate-container {
  // items in this container have a 10 px space between each other
  .ant-col + .ant-col {
    padding-left: 15px;
  }
}

.ant-tabs-content-holder {
  overflow: auto; // scrollbars appear when needed
}

.ant-card {
  margin-bottom: 15px;
}

.scc--edgeUpdate--card {
  .ant-card-head-title {
    font-weight: 600;
    font-size: 1.25rem;
    color: $main;
  }
}

.scc--edgeUpdate--warning-float-permanent {
  color: $main;
}
.scc--edgeUpdate--warning-float-permanent:hover {
  cursor: pointer;
}

.scc--edgeUpdate-update-schedule-column {
  font-size: 1rem;
}

.scc--edgeUpdate-update-schedule-picker-text {
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: $main;
}

.ant-table-row-selected {
  .ant-table-cell {
    background-color: $extra_light_main !important;
  }
}

// disable hover effect on disabled rows
.scc--edgeUpdate-tablerow--disabled:hover {
  background-color: $form_grey;
}

.scc--edgeupdate-tablerow--disabled {
  background-color: $form_grey;
  opacity: 0.5;

  .ant-checkbox-wrapper,
  .ant-checkbox,
  .ant-checkbox-input {
    cursor: not-allowed;
  }
}

.scc--edgeUpdate-schedule-alert {
  margin-top: 10px;
}
