@import '../../theme/carbon_colors';

.scc--tenantallocation--container{
  // items in this container have a 10 px space between each other
  .ant-col + .ant-col {
    padding-left: 15px;
  }
  h3 {
    font-size: 1.25rem;
    color: $text_dark_grey;
  }
}

.ant-tabs-content-holder {
  overflow: auto;  // scrollbars appear when needed
}

.scc--tenantallocation--card {
  .ant-card-head-title {
    font-weight: 600;
    font-size: 1.25rem;
    color: $main;
  }
}
