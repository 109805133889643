.scc--navmenu {
  flex: 1;
  overflow: auto;
}

.scc--navmenu--list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
