@import '../../theme/carbon_colors';

.scc--sidebar {
  background: whitesmoke;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 8rem;
  transition: width 0.3s ease-in-out;

  .scc--logo {
    text-align: center;
    padding: 1rem 1.3rem 1.625rem;

    img {
      max-width: 100%;
    }
  }
  .scc--software--version {
    text-align: center;
    font-size: 0.85rem;
    color: $text_grey;
    margin-bottom: 10px;
  }
  .scc--support--link {
    font-size: 0.8rem;
    color: $text_grey;
    text-decoration: none;
    display: block;
    text-align: center;
    padding: 1rem;

    img {
      display: inline-block;
      padding: 0.5rem 0;
      width: 24px;
    }

    span {
      display: block;
    }

    .anticon {
      font-size: 2rem;
      color: $main;
    }

    &:hover,
    &:active {
      color: #000000;
    }
  }
}

.scc--sidebar__closed {
  width: 0;
}
