@import '../../theme/carbon_colors';

.scc--row-spacer {
  display: block;
  height: 1rem;
}

.scc--label {
  display: inline-block;
  padding-right: 0.5rem;
  font-weight: bold;
}

.scc--value__changed {
  color: $main;
}

.bx--modal-header {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  margin: 0;
}

.bx--modal-footer {
  height: 2.5rem;

  button.bx--btn {
    padding: 0.5rem 0.625rem;
    text-align: center;
    display: block;
    font-weight: bold;
    height: 2.5rem;
  }
}

.bx--modal-header__label {
  color: $text_light;
}

.bx--btn--secondary:focus {
  outline: none;
}

.bx--modal.is-visible {
  z-index: 99999;
}

.bx--modal-close {
  &:focus,
  &:hover {
    border-color: transparent;
    background: none;
  }
}
