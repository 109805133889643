@import '../../theme/carbon_colors';

// === SKELETON ===

.bx--data-table.bx--skeleton {
  th span,
  td span {
    height: 0.5rem;
    border-radius: 1rem;

    margin: 1.25rem 0;

    &:before {
      border-radius: 1rem;
    }
  }

  th {
    border-bottom: none;
  }

  tr {
    height: 2.5rem;
  }
}

// === EMPTY STATE ===

.scc--boxlist__empty {
  padding: 5rem 1rem;
}

.scc--boxlist__empty--image {
  margin: 3rem 0 1rem;
}

.scc--bg--doves {
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    position: absolute;
    left: -15px;
    top: -5px;
    width: 47px;
    height: 32px;
    background: url('../../theme/icons/doves.svg') no-repeat top left;
    display: block;
    overflow: visible;
    z-index: -1;
  }
}

.scc--boxlist__empty--headline {
  margin-bottom: 1.5rem;
}

.scc--boxlist__empty--support {
  width: 8rem;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff !important;
}

// === GENERAL ===

.scc--boxlist--container {
  overflow: auto;
  height: 100%;
}

.bx--data-table {
  td,
  tbody th,
  tbody {
    background: none;
    color: #898989;
  }

  td {
    padding: 1rem 0.75rem 0.5rem;
  }

  tr {
    height: auto;
    cursor: pointer;
  }

  th {
    vertical-align: middle;
    position: sticky;
    top: 0;
    width: 12%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:first-of-type {
      width: auto;
    }

    &:last-of-type {
      position: sticky;
      width: 12%;
    }
  }

  .bx--table-header-label {
    font-weight: normal;
    padding: 0.55rem 0 0.35rem;
  }
}

.scc--centered,
.bx--data-table .scc--centered,
.scc--centered .bx--table-header-label {
  text-align: center;
}

.scc--box_name {
  font-weight: bold;
  font-size: 1rem;
}

.scc--configured,
.scc--connected {
  .color_fill {
    fill: $green;
  }
  .color_stroke {
    stroke: $green;
  }
}

.scc--unknown {
  .color_fill {
    fill: $grey;
  }
  .color_stroke {
    stroke: $grey;
  }
}

.scc--selected,
.scc--selected:hover,
.scc--selected:focus {
  background: $extra_light_main !important;

  td {
    background: none !important;
    color: #000000 !important;
  }
}

.scc--cell-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scc--boxlist--searched {
  color: $main;
}

.scc--boxlist--filterwarning {
  font-size: 0.8rem;
  margin-bottom: 5px;

  .anticon-filter {
    color: $main;
  }

  button {
    font-size: 0.8rem;
  }
}

.scc--boxlist--autorefresh {
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 13px;

  .ant-switch {
    margin-left: 5px;
    margin-top: -2px;
    min-width: 40px;
  }
}

.ant-table-thead {
  font-size: 1rem;
}

.scc--tablerow {
  cursor: pointer;
}
