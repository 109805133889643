@import '../../theme/carbon_colors';

.bx--modal-content {
  padding-right: 1rem !important;

  .bx--accordion__content {
    padding-right: 1rem;
  }

  .bx--accordion__heading {
    &:focus:before,
    &:active:before {
      border: none;
    }
  }
}

.bx--modal--danger {
  .bx--modal-header {
    background: none;
  }

  .bx--modal-header__heading {
    color: $text-01;
  }

  .bx--modal-close__icon {
    fill: #ffffff;
  }
}

.scc--error-details {
  font-size: 0.625rem;
  padding: 0.5rem !important;
  background: #efefef;
  margin-top: 2rem;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-height: 12rem;
  overflow: scroll;

  .bx--accordion__title {
    font-size: 0.7rem;
    line-height: 1rem;
  }

  .bx--accordion__item {
    border: none;

    & + .bx--accordion__item {
      border-top: 1px solid #dddddd;
    }
  }

  .bx--accordion__content {
    overflow: scroll;
  }
}

.scc--hidden {
  display: none;
}
