@import '../../theme/carbon_colors';

.scc--scenedetail--container {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  overflow: hidden;
  left: 100%;

  &.shown {
    left: 0;
  }

  .scc--close-button {
    position: absolute;
    top: 8px;
    left: 5px;
    z-index: 1;
  }
}

.scc--scenedetail--tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.scc--scenedetail--tabcontent {
  padding: 1rem 2rem 1rem 2rem;
}

.scc-scenedetail--title {
  margin-top: 10px;
  margin-left: 40px;
  margin-bottom: -25px;
}

.scc--scenedetail--emptyoverview {
  text-align: center;
}

.scc--scenedetail--widgettitle {
  color: $main;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375;
}
