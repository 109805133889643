@import '../../../theme/carbon_colors';

.scc--scenedetail--map--marker-title {
  font-weight: bold;
}

.popup-content-container {
  background-color: #000000bf !important;
  font-size: 0.8rem;
  width: max-content !important;
  height: max-content !important;
  max-width: 400px;
  max-height: 200px;
  color: #fff;
  border-radius: 2px !important;
  padding: 7px 20px 7px 10px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d !important;
  white-space: break-spaces !important;
  position: absolute !important;
}

.popup-arrow {
  display: none !important;
}

.popup-close {
  color: #fff;

  &:hover {
    color: $main;
  }
}
