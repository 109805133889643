@import '../../../theme/carbon_colors';

.scc--solutions--scenedetail--fullscreen {
  font-size: 2rem;
  color: $main;
  float: right;
}

.fullscreen-enabled {
  background: #f0f2f5;
  .ant-card-extra {
    display: none;
  }
}

.scc--solutions--add-widget-button {
  align-self: center;
}

.scc--solutions--add-widget-preview {
  min-height: 420px;
}

.scc--solutions--heatmap-toggle {
  background-color: $main !important;
}
