@import '../../theme/carbon_colors';

.scc--boxcontextual-container {
  height: 100%;
  overflow: hidden;

  .bx--row,
  .bx--grid {
    height: 100%;
  }

  .ScrollbarsCustom-TrackY {
    top: 2rem !important;
    right: -11px !important;
    height: calc(100% - 3rem) !important;
  }
}

.scc--table--action {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
}

.scc--table--action:hover {
  background: none;
  box-shadow: none;
}

.scc--boxcontextual-container.sceneNote {
  .scc--boxcontextual-container--sceneNote {
    display: block;
  }
}

.scc--boxcontextual-container--sceneNote {
  display: none;
  background: $main;
  padding: 10px;
  margin-bottom: 10px;
  a {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
  }
}

.scc--boxcontextual-container--header {
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
  .ant-divider-vertical {
    height: auto;
  }
  .ant-col {
    padding: 5px;
  }
  .scc--boxdetail__header--boxname {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
  }
  .scc--boxdetail__header--streamname {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
  }
}

.scc--boxcontextual-container--closeheadline {
  .scc--close-button {
    margin-bottom: -10px;
    margin-left: -7px;
  }
}

.scc--boxcontextual-container--statuscell {
  display: flex;
  align-items: center;
  .div {
    justify-content: center;
  }
}

.scc--no-padding {
  padding: 0;
}

.scc--padding-right {
  padding-right: 10px;
}

.scc--flex--column {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.scc--boxcontextual--table {
  margin-bottom: 1rem;
}

#scc--draw--canvas {
  position: relative;
  overflow: hidden;
  margin: auto;
  height: 100%;
}

.scc--boxcontextual--btn-save--container {
  position: sticky;
  bottom: 1rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scc--draw--canvas--wrapper {
  background: $form-fields-background;
  position: relative;
}
