@import '../../../theme/carbon_colors';

legend.scc--icon--doves {
  &:before {
    background-image: url(../../../theme/icons/doves.svg);
  }
}

.bx--select-input:disabled {
  color: gray;
  background-color: #f2f5f7;
  border-bottom-color: transparent;
}

.bx--select-input:disabled:hover {
  color: gray;
  background-color: #f2f5f7;
  border-bottom-color: transparent;
}

.bx--text-input:disabled {
  color: gray;
  background-color: #f2f5f7;
  border-bottom-color: transparent;
}

.scc--solutions--delete-scene-button {
  float: right;
}
.scc--solutions--cancel-scene-button {
  float: right;
}

.scc--location-picker-row {
  .bx--form-item {
    margin-right: 20px;
  }
  .scc--location-picker-button {
    margin-top: 5px;
  }
}

.scc--solutions-scene-timezone {
  margin-top: 10px;
  .bx--text-input {
    background-color: transparent;
    &:hover {
      cursor: auto;
    }
    &:active {
      border: none;
    }
  }
}
.bx--text-input--invalid {
  outline: none !important;
  border-color: #da1e28 !important;
}
.bx--number-input {
  width: 100%;
  input {
    background-color: $form-fields-background !important;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    font-size: 0.875rem !important;
    line-height: 40px !important;
    font-family: inherit !important;

    &:focus,
    &:active {
      outline: none !important;
      border-color: $main !important;
    }

    &::placeholder {
      line-height: 40px;
      font-weight: normal;
      color: $text_light;
    }
  }
  input[data-invalid] {
    outline: none !important;
    border-color: #da1e28 !important;
  }
  // some fixes for the stepper buttons
  .bx--number__controls {
    flex-direction: row;
    height: 100% !important;
    padding: 1px !important;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
  }
  .bx--number__control-btn:hover {
    background-color: #e5e5e5 !important;
  }
  .bx--number__control-btn {
    height: 100% !important;
    &.bx--number__control-btn.up-icon svg {
      top: 0;
    }
    &.bx--number__control-btn.down-icon svg {
      top: 0;
    }
  }
}
