@import '../../theme/carbon_colors';

.scc--datadiscovery--widget-anprImage {
  margin-top: 25px;
  margin-bottom: -10px;
  display: flex;
  justify-content: center;
}

.scc--datadiscovery--widget-anprImage-error {
  padding: 10px;
}

.scc--datadiscovery--widget-exportbutton {
  background: $main;
  border-color: $main;
  float: right;
  margin-left: 10px;
}

.scc--datadiscovery--datapoints {
  text-align: center;
  .anticon {
    font-size: 1.3rem;
    color: $main;
  }
}
