@font-face {
  font-family: 'OpenSans';
  font-weight: normal;
  src: url('./theme/fonts/open-sans/OpenSans.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSansBold';
  font-weight: bold;
  src: url('./theme/fonts/open-sans/OpenSansBold.ttf') format('truetype');
}

// === FONTS ===

$body-short-01: (
  font-family: 'OpenSans',
  font-size: carbon--type-scale(2),
  font-weight: carbon--font-weight('regular'),
  line-height: carbon--rem(18px),
  letter-spacing: 0.16px
);

$button-border-width: 0;

// === GRID ===

$feature-flags: (
  grid-columns-16: true,
  ui-shell: true
);

@import 'theme/carbon_colors';
@import 'theme/antd-modified.scss';
@import '~carbon-components/scss/globals/scss/styles.scss';
