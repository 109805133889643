@import '../../theme/carbon_colors';

.scc--devicehealth__header {
  background: #fff;
  margin-bottom: 0px;
}

.scc--devicehealth__header--rebootDevice-button {
  float: right;
  vertical-align: middle;
  margin-right: 10px;
}

.scc--devicehealth__header--retrieveLogs-button {
  float: right;
  vertical-align: middle;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 5px;
}

.ant-collapse {
  margin-bottom: 20px;
}

.ant-collapse-item-active {
  .ant-collapse-header {
    color: $main !important;
  }
}

.scc--devicehealth__header--boxname {
  .ant-collapse-header {
    font-size: 16px;
  }
}
.ant-collapse-extra {
  margin-bottom: 0px;
}
.scc--devicehealth__card {
  vertical-align: top;
  margin: 0.5%;
  border-color: $grey;
}

.ant-statistic-title {
  color: $main;
}

.recharts-brush {
  rect {
    stroke: $grey;
  }
  .recharts-brush-slide {
    fill: lighten($grey, 10%);
    fill-opacity: 0.8;
  }
  .recharts-brush-traveller {
    rect {
      fill: $main;
      fill-opacity: 1;
    }
  }
}
