@import '../../theme/carbon_colors';

.scc--boxdetail__header {
  background: #fff;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.scc--boxdetail__header--boxname {
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: bold;
}

.scc--boxdetail__header--streamname {
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: bold;
  color: $text_dark_grey;
}

.scc--boxdetail__header--boxId {
  margin-top: 10px;
  font-size: 0.75rem;
}
.scc--boxdetail__header--serial {
  margin-top: 2px;
  font-size: 0.75rem;
}

.scc--boxdetail--stream-header {
  font-size: 0.75rem;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
}

.scc--boxdetail--blur-hint {
  margin-left: auto;
  span {
    color: $main;
    cursor: pointer;
  }
  button {
    padding: 0;
  }
}

.scc--boxcontextual--table--copyid-button,
.scc--boxcontextual--table--copyid-button:hover,
.scc--boxcontextual--table--copyid-button:focus {
  border: 0;
  background: transparent;
}

.scc--boxcontextual--triggerId {
  font-size: 0.75rem;
}

.scc--boxdetail--copy-id-serial-button {
  border: 0;
  margin-left: 3px;
  height: 20px;
  width: 20px;
}

.scc--boxdetail--close-blur-hint-button {
  border: 0;
  margin-left: 3px;
  height: 20px;
  width: 20px;
}

.scc--boxdetail__header--boxname__notset {
  color: $text-grey;
}

.scc--box_id_serial {
  font-size: 0.75rem;
}

.scc--deviceconfig-container {
  overflow: hidden;
  height: 100%;

  .bx--grid,
  .bx--row {
    overflow: auto;
    height: 100%;

    & > div {
      height: 100%;
    }
  }

  .bx--grid {
    margin-left: 1rem;
  }
}

.bx--form {
  margin-bottom: 1rem;
}

.bx--tooltip {
  width: 18rem;
  max-width: 18rem;

  .bx--tooltip__caret {
    left: auto;
    right: 5px;
  }
}

.bx--tooltip__trigger:focus {
  outline: none !important;
}

.bx--text-input--password__visibility__toggle {
  svg {
    fill: $text_light !important;
    outline: none !important;
  }

  &:hover svg,
  &:focus svg {
    fill: $text-grey !important;
  }
}

input[data-invalid],
.bx--text-input__field-wrapper[data-invalid],
.bx--text-area__wrapper[data-invalid] > .bx--text-area--invalid,
.bx--select-input__wrapper[data-invalid],
.bx--list-box[data-invalid],
.bx--combo-box[data-invalid] .bx--text-input {
  outline: none;
  border-color: $error;

  &:active,
  &:focus {
    outline: none;
    border-color: $error;
  }
}

.bx--select-input__wrapper[data-invalid] .bx--select-input,
.bx--select-input__wrapper[data-invalid] .bx--select-input:focus {
  outline: none;
  border: 1px solid $error;
}

.bx--select-input__wrapper {
  width: 100%;
}

.scc--boxdetail--container {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: left 0.3s ease-in-out;
  left: 100%;

  &.shown {
    left: 10rem;
  }

  .scc--close-button {
    position: absolute;
    top: 8px;
    left: 5px;
  }
}

.scc--close-button {
  display: block;
}

.scc--fill-main {
  fill: $light_main;

  a &:hover,
  a &:focus {
    fill: $main;
  }
}

.scc--boxdetail--canvas {
  position: relative;

  .bx--btn--primary {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
  }
}

.scc--boxdetail__header--metadata {
  margin-left: 20px;
  .ant-table-cell {
    padding: 1px 2px;
    font-size: 95%;
  }
}

.scc--boxdetail__header--metadata-title {
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.scc--boxdetail__header--metadata-name {
  color: $main;
  .ant-select {
    color: $main;
    width: 100%;
    padding-left: 0px;
  }
}

.scc--boxdetail__header--edit-button {
  margin-left: 8px;
  margin-top: 5px;
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.scc--boxdetail__header--metadata-invalid {
  border-color: $error_red;
}

.scc--boxdetail__header--metadata-alert {
  font-size: 80%;
  padding: 2px 4px;
  margin: 4px 1px;
}
.scc--boxdetail__header--metadata-delete {
  .ant-btn {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.scc--boxdetail__header--tag {
  margin-top: 5px;
}

.scc--boxdetail__header--tag-clickable {
  cursor: pointer;
}
